import type {
    Blog,
    Car,
    Filters,
    VehicleSearchResult,
} from '@/types';
import {filtersAdapter, vehicleDataAdapter} from './adapters';
import { trackFilterEvent } from '@/libraries/analytics';
import { type Router } from 'vue-router';

let baseUrl = '';
let requestLanguage = navigator.language;
let router: Router | null = null;

export default function initApi(url: string, routerInstance: Router, language?: string) {
    baseUrl = url;
    if (language) {
        requestLanguage = language;
    }
    router = routerInstance;
}

const request = async (
    endpoint: string,
    data: object | null = null,
    returnAsJson: boolean = true
) => {
    const url = `${baseUrl}/${endpoint}`;
    const response = await fetch(url, {
        method: data ? 'POST' : 'GET',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Accept-Language': requestLanguage,
        },
        body: data ? JSON.stringify(data) : null,
    });

    if (response.ok) {
        if (!returnAsJson) {
            return response;
        }
        return await response.json();
    }

    // Error handling
    throw new Error(`${response.status} - ${response.statusText} - ${url}`);
};

export const getTranslations = (language: string) =>
    request(`translations/${language}`);

export const getVehicleMeta = () => request('vehicles/meta');

//Loads data from the backend and transfer it to the object of Car type
export const getVehicleData = async (
    vehicleId: number,
    type: string
): Promise<Car> => {
    const data = await request(`vehicles/info/${vehicleId}?type=${type}`);
    return vehicleDataAdapter(data);
};

export const getVehicleDataByUrlKey = async (
    urlKey: string,
    type: string
): Promise<Car> => {
    const urlParam = encodeURIComponent(encodeURIComponent(urlKey));
    try {
        const data = await request(`vehicles/vehicleDataByUrlKey/${urlParam}`);
        return vehicleDataAdapter(data);
    } catch (e) {
        if (router) {
            router.replace({
                name: 'not-found',
                params: {any: '404'},
            });
        }
        throw e;
    }
};

export const getVehicleIdByUrlKey = async (
    urlKey: string,
): Promise<number> => {
    // Double encode to prevent Laravel from picking up forward slashes as part of the route
    const urlParam = encodeURIComponent(encodeURIComponent(urlKey));
    const data = await request(`vehicles/vehicleIdByUrlKey/${urlParam}`);
    return data['vehicle_id'];
}

export const getFilteredVehicles = async (
    filters: Filters,
    offset: number,
    limit: number,
    sortOrder: string
): Promise<VehicleSearchResult> => {
    const postData = filtersAdapter(filters);
    trackFilterEvent(filters);
    const queryData = new URLSearchParams({
        offset: encodeURIComponent(offset),
        limit: encodeURIComponent(limit),
        sort: encodeURIComponent(sortOrder),
    }).toString();

    const data = await request(`vehicles/search?${queryData}`, postData);

    return data;
};

export const getSimilarVehicles = async (vehicleId: number) => {
    const data = await request(`vehicles/similarVehicles/${vehicleId}`);
    return data;
};

export async function getBlogPost(id: string): Promise<Blog> {
    const {data} = await request(`blog/${id}`);
    return data;
}

export async function getRelatedBlogPosts(nrBlogs: number): Promise<Blog[]> {
    const {data} = await request(`blog/related/${nrBlogs}`);
    return data;
}

export async function createLead(postData: Object, app: 'autoselectr' | 'produpress' = 'autoselectr') {
    return await request(`createlead/${app}`, postData, false);
}

export async function getPartners()  {
    return await request(`partners`);
}


export async function isActivePartner(brand: string) {
    return await request(`partner/isPartner/${brand}`);
}

export async function getAllPostCitiesAndCodes() {
    return await request(`postCitiesAndCodes`);
}

export async function subscribeToGetNotifiedIfBrandBecomesPartner(
    postData: Object
) {
    return await request(`saveSubscriptionToBrand`, postData, false);
}

export async function saveClosingRequestFormReason(postData: Object) {
    return await request(`saveClosingRequestFormReason`, postData, false);
}
